import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Colorful from '@uiw/react-color-colorful'
import { hexToHsva, hsvaToHex } from '@uiw/color-convert'

import { createStyles, makeStyles } from '@mui/styles'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  Tooltip,
  Drawer,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle, AppBar, Toolbar,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import BackspaceIcon from '@mui/icons-material/Backspace'
import ClearIcon from '@mui/icons-material/Clear'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'

import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import CodeIcon from '@mui/icons-material/Code'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'
import FormatClearIcon from '@mui/icons-material/FormatClear'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'
import FontDownloadIcon from '@mui/icons-material/FontDownload'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatSizeIcon from '@mui/icons-material/FormatSize'
import SubscriptIcon from '@mui/icons-material/Subscript'
import SuperscriptIcon from '@mui/icons-material/Superscript'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import MenuIcon from '@mui/icons-material/Menu'

import * as settingsActions from '../../../store/settings/settings.actions'
import { getPrefersDarkMode, getTheme, getLogo } from '../../../store/settings/settings.selectors'
import { getAppTheme } from '../../../shared/util/getAppTheme'

import {
  BooleanLayout,
  EnumLayout,
  ForeignEntityLayout,
  RadioDirection,
  ToolbarFontStyleElement,
  ToolbarListElement,
  ToolbarAdditionalElement,
  ToolbarStyleElement,
} from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

import { setLogo } from '../../../store/settings/settings.actions'
import FileUploadComponent from '../../../shared/components/FileUploadComponent'

type ToolbarElement = {
    [key: string]: boolean
}

interface IPlaygroundProps {
  showPlayground: boolean
  setShowPlayground: (value: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      float: 'right',
      cursor: 'pointer',
      marginRight: '8px',
    },
    button: {
      margin: '8px',
    },
    grow: {
      flexGrow: 1,
    },
    language: {
      margin: '8px',
      width: '60px',
      padding: '1px',
      '&:before': {
        borderBottom: '0px',
      },
    },
    dialogPaperStyle: {
      width: '600px',
      height: '600px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    toolBar: {
      background: theme.palette.background.default,
      width: '100%',
      top: '0px',
      height: '65px',
      position: 'fixed',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    languageContainer: {
      marginRight: '10px',
    },
    margin: {
      margin: '8px',
    },
    tabs: {

    },
    tab: {

    },
    activeElement: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      marginTop: theme.spacing(1),
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    disabledPlainText: {
      color: theme.palette.text.disabled,
    },
    activeBorder: {
      border: `3px solid ${theme.palette.primary.main}`,
      padding: '5px',
      height: '100%',
    },
    higherActiveBorder: {
      border: `3px solid ${theme.palette.primary.main}`,
      padding: '12px',
      height: '100%',
    },
    inactiveElement: {
      padding: '8px',
    },
    higherInactiveElement: {
      padding: '15px',
    },
    formatIcon: {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.grey.A400}`,
      borderRadius: '2px',
      padding: '3px',
      margin: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inactiveFormatIcon: {
      opacity: '0.5',
    },
    headline: {
      marginBottom: '5px',
    },
    playgroundExample: {
      backgroundColor: theme.palette.grey.A100,
    },
  }),
)

export enum PlaygroundTooltip {
  changePrimaryColor = 'changePrimaryColor',
  changeLogo = 'changeLogo',
  changeButton = 'changeButton',
  changeInput = 'changeInput',
  changeDeleteIcon = 'changeDeleteIcon',
  changeInfoIcon = 'changeInfoIcon',
  activateFilter = 'activateFilter',
  paging = 'paging',
  nestedTables = 'nestedTables',
  useImageCropper = 'useImageCropper',
  booleanFields = 'booleanFields',
  enumFields = 'enumFields',
  referenceFields = 'referenceFields',
  numericFields = 'numericFields',
  textFields = 'textFields',
  activateRTEFunctions = 'activateRTEFunctions',
}

const Playground: React.FC<IPlaygroundProps> = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const logo: any = useSelector(getLogo)

  const [showPlayground, setShowPlayground] = useState<boolean>(props.showPlayground)
  const [showPlaygroundTooltip, setShowPlaygroundTooltip] = useState<PlaygroundTooltip | undefined>()
  const [darkMode, setDarkMode] = useState<boolean>(useSelector(getPrefersDarkMode))

  useEffect(
    () => {
      setShowPlayground(props.showPlayground)
    },
    [props.showPlayground],
  )

  const theme: Theme = useSelector(getTheme)
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  const [hsva, setHsva] = useState(hexToHsva('#2a3042'))
  const [useImageCropper, setUseImageCropper] = React.useState<boolean>(true)

  // const [embeddedEditFormMainLevel, setEmbeddedEditFormMainLevel] = React.useState<boolean>(false)
  const [referenceFilter, setReferenceFilter] = React.useState<boolean>(true)
  const [standardFilter, setStandardFilter] = React.useState<boolean>(true)
  const [buttonStyle, setButtonStyle] = React.useState<'text'|'outlined'|'contained'>('outlined')
  const [textfieldStyle, setTextfieldStyle] = React.useState<'filled'|'outlined'|'standard'>('outlined')
  const [deleteIcon, setDeleteIcon] = React.useState<'clear'|'backspace'|'highlightOff'| 'delete' |'deleteForever'>('delete')
  const [infoIcon, setInfoIcon] = React.useState<'help' | 'info' | 'lightbulb' | 'helpOutline' | 'helpCenter'>('info')
  // const [treeViewLevel, setTreeViewLevel] = React.useState<number>(3)
  const [maxTextLinesInTableView, setMaxTextLinesInTableView] = React.useState<number>(3)
  const [maxThumbnails, setMaxThumbnails] = React.useState<number>(3)
  // const [maxJumpLevel, setMaxJumpLevel] = React.useState<number>(3)
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState<number[]>([10, 15, 30, 60])
  const [initialRowsPerPage, setInitialRowsPerPage] = React.useState<number>(10)
  const [minRowsMultiline, setMinRowsMultiline] = React.useState<number>(3)
  const [maxRowsPerDialogPage, setMaxRowsPerDialogPage] = React.useState<number>(15)
  const [maxDropdownSize, setMaxDropdownSize] = React.useState<number>(50)
  const [booleanLayout, setBooleanLayout] = React.useState<BooleanLayout>(BooleanLayout.checkbox)
  const [enumLayout, setEnumLayout] = React.useState<EnumLayout>(EnumLayout.dropdown)
  const [foreignEntityLayout, setForeignEntityLayout] = React.useState<ForeignEntityLayout>(ForeignEntityLayout.autocomplete)
  const [radioDirection, setRadioDirection] = React.useState<RadioDirection>(RadioDirection.row)
  const [hideNumberInputArrows, setHideNumberInputArrows] = React.useState<boolean>(false)
  const [readOnlyPlainText, setReadOnlyPlainText] = React.useState<boolean>(false)
  const [globalToolbar, setGlobalToolbar] = React.useState<string[]>([ToolbarStyleElement.bold, ToolbarStyleElement.italic, ToolbarStyleElement.underline, ToolbarStyleElement.strike])

  useEffect(
    () => {
      setInitialRowsPerPage(rowsPerPageOptions[0])
    },
    [rowsPerPageOptions],
  )

  useEffect(
    () => {
      dispatch(settingsActions.setAppLayout({
        initialRowsPerPage,
        rowsPerPageOptions,
        // treeViewLevel,
        // embeddedEditFormMainLevel,
        maxDropdownSize,
        maxTextLinesInTableView,
        maxRowsPerDialogPage,
        useImageCropper,
        maxThumbnails,
        booleanLayout,
        enumLayout,
        deleteIcon,
        foreignEntityLayout,
        hideNumberInputArrows,
        radioDirection,
        readOnlyPlainText,
        buttonStyle,
        minRowsMultiline,
        textfieldStyle,
        referenceFilter,
        infoIcon,
        // maxJumpLevel,
        globalToolbar,
        standardFilter,
      }))
    },
    [useImageCropper, maxThumbnails, deleteIcon, standardFilter,
      infoIcon, initialRowsPerPage, rowsPerPageOptions, minRowsMultiline, globalToolbar,
      /* treeViewLevel, embeddedEditFormMainLevel, maxJumpLevel, */ referenceFilter, textfieldStyle,
      maxDropdownSize, maxRowsPerDialogPage, booleanLayout, enumLayout, foreignEntityLayout, buttonStyle,
      hideNumberInputArrows, radioDirection, readOnlyPlainText, maxTextLinesInTableView],
  )

  const onUploadFile = (upload: any) => {
    dispatch(setLogo(upload))
  }

  const handleChangeDarkMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDarkMode(event.target.checked)
    dispatch(settingsActions.setPrefersDarkMode(event.target.checked))
  }

  useEffect(() => {
    const coloredTheme: Theme = getAppTheme(darkMode, hsvaToHex(hsva))
    dispatch(settingsActions.setTheme(coloredTheme))
  },
  [hsva],
  )
  const getRTEFormat = () => {
    const toolbar = [...globalToolbar]
    const formatObject = {
      bold: toolbar?.find(elem => elem === ToolbarStyleElement.bold),
      italic: toolbar?.find(elem => elem === ToolbarStyleElement.italic),
      underline: toolbar?.find(elem => elem === ToolbarStyleElement.underline),
      strike: toolbar?.find(elem => elem === ToolbarStyleElement.strike),
      codeBlock: toolbar?.find(elem => elem === ToolbarStyleElement.codeBlock),
      blockquote: toolbar?.find(elem => elem === ToolbarStyleElement.blockquote),
      clean: toolbar?.find(elem => elem === ToolbarStyleElement.clean),
      color: toolbar?.find(elem => elem === ToolbarAdditionalElement.color),
      background: toolbar?.find(elem => elem === ToolbarAdditionalElement.background),
      font: toolbar?.find(elem => elem === ToolbarAdditionalElement.font),
      align: toolbar?.find(elem => elem === ToolbarAdditionalElement.align),
      header: toolbar?.find(elem => elem === ToolbarAdditionalElement.header),
      sub: toolbar?.find(elem => elem === ToolbarFontStyleElement.sub),
      super: toolbar?.find(elem => elem === ToolbarFontStyleElement.super),
      ordered: toolbar?.find(elem => elem === ToolbarListElement.ordered),
      bullet: toolbar?.find(elem => elem === ToolbarListElement.bullet),
    }
    return formatObject
  }

  const getToolbarIcon = (key: any) => {
    switch (key) {
      case 'bold':
        return <FormatBoldIcon />
      case 'italic':
        return <FormatItalicIcon />
      case 'underline':
        return <FormatUnderlinedIcon />
      case 'codeBlock':
        return <CodeIcon />
      case 'blockquote':
        return <FormatQuoteIcon />
      case 'strike':
        return <StrikethroughSIcon />
      case 'clean':
        return <FormatClearIcon />
      case 'color':
        return <FormatColorTextIcon />
      case 'background':
        return <FormatColorFillIcon />
      case 'font':
        return <FontDownloadIcon />
      case 'align':
        return <FormatAlignLeftIcon />
      case 'header':
        return <FormatSizeIcon />
      case 'sub':
        return <SubscriptIcon />
      case 'super':
        return <SuperscriptIcon />
      case 'ordered':
        return <FormatListBulletedIcon />
      case 'bullet':
        return <FormatListNumberedIcon />
    }
  }

  const changeRTEFormat = (format: any) => {
    const toolbar = [...globalToolbar]
    const deleteIndex = toolbar.findIndex(elem => elem === format)
    const addIndex = 0

    if (deleteIndex >= 0) {
      toolbar.splice(deleteIndex, 1)
    } else {
      toolbar.splice(addIndex, 0, format)
    }
    setGlobalToolbar(toolbar)
  }

  const getToolbarElements = () => {
    const toolbar = [...globalToolbar]
    const toolbarElements: ToolbarElement = {}
    toolbar.map(elem => {
      toolbarElements[elem] = true
    })
    // fehlende elemente hinten anfügen
    Object.keys(getRTEFormat()).map(elem => {
      if (!toolbarElements[elem]) {
        toolbarElements[elem] = false
      }
    })
    return toolbarElements
  }

  // D&D Columnheader
  const [dragToolbar, setDragToolbar] = useState<string | null>(null)
  const [dropToolbar, setDropToolbar] = useState<string | null>(null)

  const onDragStartToolbar = (event: React.MouseEvent<HTMLDivElement>) => {
    setDragToolbar(event.currentTarget.id)
  }

  const onDragOverToolbar = (event: React.MouseEvent<HTMLDivElement>) => {
    setDropToolbar(event.currentTarget.id)
  }

  const onDragEndToolbar = (event: React.MouseEvent<HTMLDivElement>) => {
    const toolbar = [...globalToolbar]
    const newToolbar = []

    const dragId = toolbar.findIndex(elem => elem === dragToolbar)
    const dropId = toolbar.findIndex(elem => elem === dropToolbar)

    if (dragId < dropId) {
      toolbar.map((elem, index) => {
        if (index !== dragId) {
          newToolbar.push(elem)
        }
        if (index === dropId) {
          dragToolbar && newToolbar.push(dragToolbar)
        }
      })
    }
    if (dragToolbar && dragId === dropId) {
      newToolbar.push(dragToolbar)
    }
    if (dragId > dropId) {
      toolbar.map((elem, index) => {
        if (index === dropId) {
          dragToolbar && newToolbar.push(dragToolbar)
        }
        if (index !== dragId) {
          newToolbar.push(elem)
        }
      })
    }
    setGlobalToolbar(newToolbar)
  }

  const makeTooltipContent = (key: string, example: ReactNode) => (
    <>
      <Box sx={{ padding: '0 40px 40px 40px' }}>
        <Typography variant='h6'>{t(`playground:title${key}`)}</Typography>
        <Typography variant='body2'>
          {t(`playground:tooltip${key}`)}
        </Typography>
      </Box>
      <Box className={classes.playgroundExample}>
        <Box sx={{ padding: '40px' }}>
          <Typography variant='h6' sx={{ color: theme.palette.grey.A700 }}>{t('layout:example')}</Typography><br /><br />
          <Box>
            {example}
          </Box>
        </Box>
      </Box>
    </>
  )
  const getTooltipContent = () => {
    switch (showPlaygroundTooltip) {
      case PlaygroundTooltip.changePrimaryColor:
        return makeTooltipContent('PrimaryColor',
          <Grid container>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%', backgroundColor: '#e56c2b' }} variant='contained'>{t('colors:red')}</Button>
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%', backgroundColor: '#7bd36b' }} variant='contained'>{t('colors:green')}</Button>
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%', backgroundColor: '#6188ad' }} variant='contained'>{t('colors:blue')}</Button>
            </Grid>
          </Grid>)
      case PlaygroundTooltip.changeLogo:
        return makeTooltipContent('Logo',
          <Grid container spacing={2} direction='column'>
            <Grid item sx={{ width: '100%' }}>
              <AppBar position='static'>
                <Toolbar sx={{ backgroundColor: '#fff' }}>
                  <IconButton><MenuIcon sx={{ height: '30px', width: '30px' }} /></IconButton>
                  <div style={{ marginLeft: '20px' }}><img src='/logoDeviLight.png' style={{ height: '55px' }} alt='' /></div>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <AppBar position='static'>
                <Toolbar sx={{ backgroundColor: '#fff' }}>
                  <IconButton><MenuIcon sx={{ height: '30px', width: '30px' }} /></IconButton>
                  <div style={{ marginLeft: '20px' }}><img src='/logo_3m5.png' style={{ height: '55px' }} alt='' /></div>
                </Toolbar>
              </AppBar>
            </Grid>
          </Grid>)
      case PlaygroundTooltip.changeButton:
        return makeTooltipContent('Buttons',
          <Grid container>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%' }} variant='text'>{t('layout:button')}</Button>
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%' }} variant='outlined'>{t('layout:button')}</Button>
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <Button style={{ width: '100%' }} variant='contained'>{t('layout:button')}</Button>
            </Grid>
          </Grid>)
      case PlaygroundTooltip.changeInput:
        return makeTooltipContent('Inputs',
          <Grid container>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <TextField value={t('layout:textfield')} fullWidth variant='filled' />
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px' }}>
              <TextField value={t('layout:textfield')} fullWidth variant='outlined' />
            </Grid>
            <Grid item xs={4} sx={{ padding: '10px', display: 'flex', alignItems: 'flex-end' }}>
              <TextField value={t('layout:textfield')} fullWidth variant='standard' />
            </Grid>
          </Grid>)
      case PlaygroundTooltip.changeDeleteIcon:
        return makeTooltipContent('IconsDelete',
          <Grid container>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><DeleteIcon /></IconButton>
            </Grid>
            <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><BackspaceIcon /></IconButton>
            </Grid>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><ClearIcon /></IconButton>
            </Grid>
            <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><HighlightOffIcon /></IconButton>
            </Grid>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><DeleteForeverIcon /></IconButton>
            </Grid>
          </Grid>)
      case PlaygroundTooltip.changeInfoIcon:
        return makeTooltipContent('IconsInformation',
          <Grid container>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><InfoIcon /></IconButton>
            </Grid>
            <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><LightbulbIcon /></IconButton>
            </Grid>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><HelpOutlineIcon /></IconButton>
            </Grid>
            <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><HelpCenterIcon /></IconButton>
            </Grid>
            <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <IconButton className={classes.activeIcon}><HelpIcon /></IconButton>
            </Grid>
          </Grid>)
      case PlaygroundTooltip.activateFilter:
        return makeTooltipContent('Filters', <img src='/screenshot_filters.png' alt='' style={{ width: '100%' }} />)
      case PlaygroundTooltip.nestedTables:
        return makeTooltipContent('NestedTables', <img src='/screenshot_nested_tables.png' alt='' style={{ width: '100%' }} />)
      case PlaygroundTooltip.paging:
        return makeTooltipContent('Paging', <img src='/screenshot_paging.png' alt='' style={{ width: '100%' }} />)
      case PlaygroundTooltip.activateRTEFunctions:
        return makeTooltipContent('TextEditorFunctions', <img src='/screenshot_rte.png' alt='' style={{ width: '100%' }} />)
      case PlaygroundTooltip.useImageCropper:
        return makeTooltipContent('ImageCropper', <img src='/screenshot_image_cropper.png' alt='' style={{ width: '100%' }} />)
      case PlaygroundTooltip.booleanFields:
        return makeTooltipContent('BooleanFields',
          <Grid container>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Switch defaultChecked color='primary' />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox defaultChecked color='primary' />
            </Grid>
          </Grid>)
      case PlaygroundTooltip.enumFields:
        return makeTooltipContent('EnumFields',
          <Grid container direction='column' spacing={4}>
            <Grid item xs={6}>
              <Autocomplete
                disableClearable options={['Element 1', 'Element 2']} defaultValue='Element 1'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    color='primary'
                    variant={textfieldStyle}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <RadioGroup row defaultValue='Element 1'>
                <FormControlLabel value='Element 1' control={<Radio />} label={<>Element 1</>} />
                <FormControlLabel value='Element 2' control={<Radio />} label={<>Element 2</>} />
              </RadioGroup>
            </Grid>
          </Grid>,
        )
      case PlaygroundTooltip.referenceFields:
        return makeTooltipContent('ReferenceFields',
          <Grid container sx={{ alignItems: 'start' }} spacing={0}>
            <Grid item xs={12}><img src='/screenshot_reference_fields_3.png' alt='' style={{ width: '100%' }} /></Grid>
            <Grid item xs={6}><img src='/screenshot_reference_fields_1.png' alt='' style={{ width: '100%' }} /></Grid>
            <Grid item xs={6}><img src='/screenshot_reference_fields_2.png' alt='' style={{ width: '100%' }} /></Grid>
          </Grid>)
      case PlaygroundTooltip.numericFields:
        return makeTooltipContent('NumberFields',
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              display: 'none',
                            },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                }}
                type='number' defaultValue={1} label={t('layout:hideNumberInputArrows')}
              />
            </Grid>
            <Grid xs={6} item>
              <TextField fullWidth type='number' defaultValue={1} label={t('layout:hideNumberInputArrows')} />
            </Grid>
          </Grid>)
      case PlaygroundTooltip.textFields:
        return makeTooltipContent('TextFields',
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField disabled fullWidth value={t('layout:disabledInputValue')} label='Label' />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' className={classes.disabledPlainText}>Label</Typography>
              <Typography variant='body2'>
                {t('layout:disabledInputValue')}
              </Typography>
            </Grid>
          </Grid>)
      default:
        return (
          <Box sx={{ padding: '0 40px 40px 40px' }}>
            <Typography variant='h6'>{t('playground:titleDefault')}</Typography>
            <Typography variant='body2'>{t('playground:tooltipDefault')}</Typography>
          </Box>)
    }
  }

  const toggleDrawer =
    () =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return
        }
        props.setShowPlayground(!showPlayground)
        setShowPlayground(!showPlayground)
      }

  const playground = () => (
    <Box
      sx={{ width: 500, padding: '20px' }}
      role='presentation'
    >
      <>
        <Typography variant='h6'>{t('layout:playground')}</Typography>
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={selectedTabIndex}
          textColor='secondary'
          indicatorColor='primary'
          className={classes.tabs}
        >
          <Tab
            onClick={() => setSelectedTabIndex(0)}
            label={
              <Typography>{t('layout:tabLayout')}</Typography>
            }
            value={0}
            key='Tab0'
            className={classes.tab}
          />
          <Tab
            onClick={() => setSelectedTabIndex(1)}
            label={
              <Typography>{t('layout:tabTable')}</Typography>
            }
            value={1}
            key='Tab1'
            className={classes.tab}
          />
          <Tab
            onClick={() => setSelectedTabIndex(2)}
            label={
              <Typography>{t('layout:tabEdit')}</Typography>
            }
            value={2}
            key='Tab2'
            className={classes.tab}
          />
          <Tab
            onClick={() => setSelectedTabIndex(3)}
            label={
              <Typography>{t('layout:tabRTE')}</Typography>
            }
            value={3}
            key='Tab3'
            className={classes.tab}
          />
        </Tabs>
        <Box style={{ marginTop: '20px' }}>
          {/* Tab 1: Layout ampassen */}
          {selectedTabIndex === 0 &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={1} className={classes.headline}>
                    <Typography><b>{t('layout:changePrimaryColor')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changePrimaryColor)} />
                  </Stack>
                  <Colorful
                    color={hsva}
                    disableAlpha
                    onChange={(color) => {
                      setHsva({ ...hsva, ...color.hsva })
                    }}
                  />
                </Grid>
                <Grid item xs={6} container>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1} className={classes.headline}>
                      <Typography><b>{t('layout:changeLogo')}</b></Typography>
                      <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changeLogo)} />
                    </Stack>
                    <>
                      <img src={logo} height='55px' style={{ maxWidth: '100%' }} />
                      <FileUploadComponent
                        label={t('layout:upload')}
                        fullWidth
                        disabled={false}
                        onUpload={onUploadFile}
                      />
                    </>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1} className={classes.headline}>
                      <Typography><b>{t('layout:darkMode')}</b></Typography>
                    </Stack>
                    <Switch
                      checked={darkMode}
                      onChange={handleChangeDarkMode}
                      color='primary'
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Button Styling */}
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:button')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changeButton)} />
                  </Stack>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px' }}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => setButtonStyle('text')}>
                    <Button style={{ width: '100%' }} disabled={buttonStyle !== 'text'} variant='text'>{t('layout:button')}</Button>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px' }}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => setButtonStyle('outlined')}>
                    <Button style={{ width: '100%' }} disabled={buttonStyle !== 'outlined'} variant='outlined'>{t('layout:button')}</Button>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px' }}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => setButtonStyle('contained')}>
                    <Button style={{ width: '100%' }} disabled={buttonStyle !== 'contained'} variant='contained'>{t('layout:button')}</Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:inputs')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changeInput)} />
                  </Stack>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px' }}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => setTextfieldStyle('filled')}>
                    <TextField
                      sx={textfieldStyle === 'filled' ? {} : { position: 'relative', zIndex: -1 }}
                      value={t('layout:textfield')} disabled={textfieldStyle !== 'filled'} fullWidth variant='filled'
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px' }}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => setTextfieldStyle('outlined')}>
                    <TextField
                      sx={textfieldStyle === 'outlined' ? {} : { position: 'relative', zIndex: -1 }}
                      value={t('layout:textfield')} disabled={textfieldStyle !== 'outlined'} fullWidth variant='outlined'
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ padding: '10px', display: 'flex', alignItems: 'flex-end' }}>
                  <Box sx={{ cursor: 'pointer', display: 'flex' }} onClick={() => setTextfieldStyle('standard')}>
                    <TextField
                      sx={textfieldStyle === 'standard' ? {} : { position: 'relative', zIndex: -1 }}
                      value={t('layout:textfield')} disabled={textfieldStyle !== 'standard'} fullWidth variant='standard'
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* Trash Icon Styling */}
              <Grid item xs={12}>
                <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                  <Typography><b>{t('layout:delete')}</b></Typography>
                  <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changeDeleteIcon)} />
                </Stack>
              </Grid>
              <Grid container>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={deleteIcon === 'delete' ? classes.activeIcon : ''} onClick={() => setDeleteIcon('delete')}><DeleteIcon /></IconButton>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={deleteIcon === 'backspace' ? classes.activeIcon : ''} onClick={() => setDeleteIcon('backspace')}><BackspaceIcon /></IconButton>
                </Grid>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={deleteIcon === 'clear' ? classes.activeIcon : ''} onClick={() => setDeleteIcon('clear')}><ClearIcon /></IconButton>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={deleteIcon === 'highlightOff' ? classes.activeIcon : ''} onClick={() => setDeleteIcon('highlightOff')}><HighlightOffIcon /></IconButton>
                </Grid>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={deleteIcon === 'deleteForever' ? classes.activeIcon : ''} onClick={() => setDeleteIcon('deleteForever')}><DeleteForeverIcon /></IconButton>
                </Grid>
              </Grid>
              {/* Info Icon Styling */}
              <Grid item xs={12}>
                <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                  <Typography><b>{t('layout:info')}</b></Typography>
                  <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.changeInfoIcon)} />
                </Stack>
              </Grid>
              <Grid container>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={infoIcon === 'info' ? classes.activeIcon : ''} onClick={() => setInfoIcon('info')}>
                    <InfoIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={infoIcon === 'lightbulb' ? classes.activeIcon : ''} onClick={() => setInfoIcon('lightbulb')}>
                    <LightbulbIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={infoIcon === 'helpOutline' ? classes.activeIcon : ''} onClick={() => setInfoIcon('helpOutline')}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={infoIcon === 'helpCenter' ? classes.activeIcon : ''} onClick={() => setInfoIcon('helpCenter')}>
                    <HelpCenterIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <IconButton className={infoIcon === 'help' ? classes.activeIcon : ''} onClick={() => setInfoIcon('help')}>
                    <HelpIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {/* ggf. noch verschiedene Layouts anbieten > dazu vorgaben machen und per Click auswählen lassen */}
              {/* 1. Tab Navi seitlich */}
            </>}
          {/* Tab 2: Tabellen anpassen */}
          {selectedTabIndex === 1 &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ paddingTop: '24px!important' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '0' }}>
                      <Typography><b>{t('layout:filter')}</b></Typography>
                      <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.activateFilter)} />
                    </Stack>
                    <Switch
                      checked={standardFilter}
                      onChange={(event, value) => {
                        setStandardFilter(value)
                        setReferenceFilter(value)
                      }}
                      color='primary'
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sx={{ paddingTop: '5px!important' }}> */}
                {/*  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                {/*    <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '0' }}> */}
                {/*      <Typography><b>{t('layout:referenceFilter')}</b></Typography> */}
                {/*      <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.activateReferenceFilter)} /> */}
                {/*    </Stack> */}
                {/*    <Switch */}
                {/*      checked={referenceFilter} */}
                {/*      onChange={() => { setReferenceFilter(!referenceFilter) }} */}
                {/*      color='primary' */}
                {/*    /> */}
                {/*  </Box> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} sx={{ paddingTop: '5px!important' }}> */}
                {/*  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                {/*    <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '0' }}> */}
                {/*      <Typography><b>{t('layout:embeddedEditHigherMainLevel')}</b></Typography> */}
                {/*      <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.activateEditingInTable)} /> */}
                {/*    </Stack> */}
                {/*    <Switch */}
                {/*      checked={embeddedEditFormMainLevel} */}
                {/*      onChange={() => { setEmbeddedEditFormMainLevel(!embeddedEditFormMainLevel) }} */}
                {/*      color='primary' */}
                {/*    /> */}
                {/*  </Box> */}
                {/* </Grid> */}
                {/* <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={embeddedEditFormHigherLevel}
                        onChange={() => { setEmbeddedEditFormHigherLevel(!embeddedEditFormHigherLevel) }}
                        color='primary'
                      />
                    }
                    label={<>{t('layout:embeddedEditFormHigherLevel')}</>}
                  />
                </Grid> */}
              </Grid>
              <Divider sx={{ margin: '20px 0' }} />
              {/* Paging Optionen */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:paging')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.paging)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t('layout:initialPageSize')}</InputLabel>
                    <Select
                      value={initialRowsPerPage}
                      label={t('layout:initialPageSize')}
                      onChange={(event) => {
                        setInitialRowsPerPage(parseInt(event.target.value as string, 10))
                      }}
                    >
                      {rowsPerPageOptions.map(elem =>
                        <MenuItem key={elem} value={elem}>{elem}</MenuItem>,
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t('layout:pageSizeOptions')}</InputLabel>
                    <Select
                      value={JSON.stringify(rowsPerPageOptions)}
                      label={t('layout:pageSizeOptions')}
                      onChange={(event) => {
                        setRowsPerPageOptions(JSON.parse(event.target.value))
                      }}
                    >
                      <MenuItem value='[10,15,30,60]'>[10,15,30,60]</MenuItem>
                      <MenuItem value='[20,40,60,80]'>[20,40,60,80]</MenuItem>
                      <MenuItem value='[10,25,50,100]'>[10,25,50,100]</MenuItem>
                      <MenuItem value='[25,50,75,100]'>[25,50,75,100]</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ margin: '20px 0' }} />
              {/* Inhalte Tabelle Styling */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:tables')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.nestedTables)} />
                  </Stack>
                </Grid>
                {/* <Grid item xs={6}> */}
                {/*  <TextField */}
                {/*    fullWidth */}
                {/*    value={treeViewLevel} */}
                {/*    label={t('layout:maxTreeViewLevel')} */}
                {/*    type='number' */}
                {/*    InputProps={{ inputProps: { min: 1, max: 5 } }} */}
                {/*    onChange={(event) => { */}
                {/*      setTreeViewLevel(parseInt(event.currentTarget.value, 10)) */}
                {/*    }} */}
                {/*  /> */}
                {/* </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={maxThumbnails}
                    type='number'
                    InputProps={{ inputProps: { min: 1, max: 5 } }}
                    label={t('layout:maxThumbnails')}
                    onChange={(event) => {
                      setMaxThumbnails(parseInt(event.currentTarget.value))
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={maxTextLinesInTableView}
                    InputProps={{ inputProps: { min: 1, max: 5 } }}
                    label={t('layout:maxTextLines')}
                    onChange={(event) => {
                      setMaxTextLinesInTableView(parseInt(event.currentTarget.value))
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}> */}
                {/*  <TextField */}
                {/*    fullWidth */}
                {/*    type='number' */}
                {/*    value={maxJumpLevel} */}
                {/*    InputProps={{ inputProps: { min: 1, max: 5 } }} */}
                {/*    label={t('layout:maxJumpLevel')} */}
                {/*    onChange={(event) => { */}
                {/*      setMaxJumpLevel(parseInt(event.currentTarget.value)) */}
                {/*    }} */}
                {/*  /> */}
                {/* </Grid> */}
              </Grid>
            </>}
          {/* Tab 3: Editmasken anpassen */}
          {selectedTabIndex === 2 &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ paddingTop: '24px!important' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '0px' }}>
                      <Typography><b>{t('layout:useImageCropper')}</b></Typography>
                      <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.useImageCropper)} />
                    </Stack>
                    <Switch
                      checked={useImageCropper}
                      onChange={() => { setUseImageCropper(!useImageCropper) }}
                      color='primary'
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ margin: '20px 0' }} />
              {/* Layout Columntypes */}
              {/* Layout boolean */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:booleanFields')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.booleanFields)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={booleanLayout === BooleanLayout.switch}
                        onChange={() => setBooleanLayout(BooleanLayout.switch)}
                        color='primary'
                      />
                    }
                    label={<>{t('layout:switch')}</>}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={booleanLayout === BooleanLayout.checkbox}
                        onChange={() => setBooleanLayout(BooleanLayout.checkbox)}
                        color='primary'
                      />
                    }
                    label={<>{t('layout:checkbox')}</>}
                  />
                </Grid>
                {/* Layout enum */}
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:enumFields')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.enumFields)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={() => setEnumLayout(EnumLayout.dropdown)}>
                    <FormLabel>{t('layout:dropdown')}</FormLabel>
                    <Autocomplete
                      sx={enumLayout === EnumLayout.dropdown ? {} : { position: 'relative', zIndex: -1 }}
                      disabled={enumLayout !== EnumLayout.dropdown}
                      disableClearable
                      options={['Element 1', 'Element 2', 'Element 3']}
                      defaultValue='Element 1'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          color='primary'
                          variant={textfieldStyle}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={() => setEnumLayout(EnumLayout.radio)}>
                    <FormControl>
                      <FormLabel>{t('layout:radio')}</FormLabel>
                      <RadioGroup
                        row
                        defaultValue='Element 1'
                      >
                        <FormControlLabel disabled={enumLayout !== EnumLayout.radio} value='Element 1' control={<Radio />} label={<>Element 1</>} />
                        <FormControlLabel disabled={enumLayout !== EnumLayout.radio} value='Element 2' control={<Radio />} label={<>Element 2</>} />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box
                    onClick={() => setRadioDirection(RadioDirection.column)}
                  >
                    <FormLabel>{t('layout:radioColumn')}</FormLabel>
                    <RadioGroup className={classes.flexColumn}>
                      <FormControlLabel disabled={radioDirection === RadioDirection.row} control={<Radio />} label='Wert 1' />
                      <FormControlLabel disabled={radioDirection === RadioDirection.row} control={<Radio />} label='Wert 2' />
                    </RadioGroup>
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box
                    onClick={() => setRadioDirection(RadioDirection.row)}
                  >
                    <FormLabel>{t('layout:radioRow')}</FormLabel>
                    <RadioGroup className={classes.flexRow}>
                      <FormControlLabel disabled={radioDirection !== RadioDirection.row} control={<Radio />} label='Wert 1' />
                      <FormControlLabel disabled={radioDirection !== RadioDirection.row} control={<Radio />} label='Wert 2' />
                    </RadioGroup>
                  </Box>
                </Grid>
                {/* Layout foreign keys */}
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:referenceFields')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.referenceFields)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={() => setForeignEntityLayout(ForeignEntityLayout.autocomplete)}>
                    <FormLabel>{t('layout:autocomplete')}</FormLabel>
                    <Autocomplete
                      disableClearable
                      disabled={foreignEntityLayout !== ForeignEntityLayout.autocomplete}
                      options={['Element 1', 'Element 2', 'Element 3']}
                      defaultValue='Element 1'
                      sx={foreignEntityLayout === ForeignEntityLayout.autocomplete ? {} : { position: 'relative', zIndex: -1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          color='primary'
                          variant={textfieldStyle}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={() => setForeignEntityLayout(ForeignEntityLayout.popUp)}>
                    <FormLabel>{t('layout:popUp')}</FormLabel>
                    <img style={{ width: '100%', opacity: foreignEntityLayout === ForeignEntityLayout.popUp ? 1 : 0.3 }} src='/referenceDialog.png' />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={maxDropdownSize}
                    label={t('layout:maxDropdownSize')}
                    type='number'
                    InputProps={{ inputProps: { min: 5, max: 100 } }}
                    onChange={(event) => {
                      setMaxDropdownSize(parseInt(event.currentTarget.value, 10))
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={maxRowsPerDialogPage}
                    label={t('layout:maxRowsPerDialogPage')}
                    type='number'
                    InputProps={{ inputProps: { min: 5, max: 50 } }}
                    onChange={(event) => {
                      setMaxRowsPerDialogPage(parseInt(event.currentTarget.value, 10))
                    }}
                  />
                </Grid>
              </Grid>
              {/* Feinschliff */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('playground:titleNumberFields')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.numericFields)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    onClick={() => setHideNumberInputArrows(true)}
                  >
                    <TextField
                      disabled={!hideNumberInputArrows}
                      fullWidth
                      sx={{
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.main,
                          borderWidth: 2,
                        },
                        ...(hideNumberInputArrows ? undefined : { position: 'relative', zIndex: -1 }),
                      }}
                      type='number' value={1} label={t('layout:hideNumberInputArrows')}
                    />

                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box
                    onClick={() => setHideNumberInputArrows(false)}
                  >
                    <TextField sx={hideNumberInputArrows ? { position: 'relative', zIndex: -1 } : {}} fullWidth disabled={hideNumberInputArrows} type='number' value={1} label={t('layout:hideNumberInputArrows')} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:textfields')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.textFields)} />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    onClick={() => setReadOnlyPlainText(false)}
                  >
                    <TextField
                      sx={readOnlyPlainText ? { position: 'relative', zIndex: -1 } : {}} disabled={readOnlyPlainText}
                      fullWidth InputProps={{ readOnly: true }} value={t('layout:disabledInputValue')} label='Label'
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    onClick={() => setReadOnlyPlainText(true)}
                  >
                    <Typography variant='body1' className={classes.disabledPlainText}>
                      Label
                    </Typography>
                    <Typography variant='body2' sx={readOnlyPlainText ? {} : { color: theme.palette.text.disabled }}>
                      {t('layout:disabledInputValue')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={minRowsMultiline}
                    label={t('layout:minRowsMultiline')}
                    type='number'
                    InputProps={{ inputProps: { min: 1, max: 10 } }}
                    onChange={(event) => {
                      setMinRowsMultiline(parseInt(event.currentTarget.value, 10))
                    }}
                  />
                </Grid>
              </Grid>
            </>}
          {/* Tab 4: RTE anpassen */}
          {selectedTabIndex === 3 &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} className={classes.headline} sx={{ marginTop: '20px' }}>
                    <Typography><b>{t('layout:activateRTEFunctions')}</b></Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} color='disabled' onClick={() => setShowPlaygroundTooltip(PlaygroundTooltip.activateRTEFunctions)} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ margin: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                    {Object.keys(getToolbarElements()).map((key: any) =>
                      <Tooltip key={key} title={t(`crude:formatTooltip${key}`) as string} leaveDelay={0} placement='bottom-end'>
                        <Box
                          id={key}
                          draggable={getToolbarElements()[key]}
                          onDragStart={onDragStartToolbar}
                          onDragOver={onDragOverToolbar}
                          onDragEnd={onDragEndToolbar}
                          key={key}
                          className={`${classes.formatIcon} ${!getToolbarElements()[key] && classes.inactiveFormatIcon}`}
                          onClick={() => changeRTEFormat(key)}
                        >
                          {getToolbarIcon(key)}
                        </Box>
                      </Tooltip>,
                    )}
                  </Box>

                </Grid>
              </Grid>
            </>}
        </Box>
      </>
    </Box>
  )
  return (
    <>
      <Drawer
        anchor='right'
        variant='persistent'
        open={showPlayground}
        onClose={toggleDrawer()}
      >
        {playground()}
      </Drawer>
      <Dialog onClose={() => setShowPlaygroundTooltip(undefined)} open={showPlaygroundTooltip !== undefined || false}>
        <DialogTitle className={classes.dialogTitle}>
          <IconButton onClick={() => setShowPlaygroundTooltip(undefined)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%', padding: 0 }}>{getTooltipContent()}</DialogContent>
      </Dialog>
    </>
  )
}

export default Playground
