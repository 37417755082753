import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Cropper from 'react-cropper'

import { Theme, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import 'cropperjs/dist/cropper.css'

interface IProps {
  image: string
  imageRatio?:number
  maximumFileSize?: number
  previewContainer: string
  changeImage: (image: string) => void
  useOrigin: () => void
}

const ImageCropperComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [image, setImage] = useState(props.image)
  const [cropper, setCropper] = useState<any>()

  const useStyles = makeStyles((theme: Theme) => ({
    imageCropper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }))

  const classes = useStyles()

  useEffect(() => {
    props.image && setImage(props.image)
  }, [props.image])

  const fileSize = (imageBaseString: string) => {
    const suffix = 1 // y will be 2 if Base64 ends with '==' and 1 if Base64 ends with '='.
    const imageSize = imageBaseString.length
    const size = (imageSize * (3 / 4)) - suffix

    return size
  }

  const getCropData = () => {
    const cropData = cropper.getCroppedCanvas().toDataURL()
    const fileSizeCalc = fileSize(cropData)
    if (!props.maximumFileSize || (fileSizeCalc / 1024 / 1024) < props.maximumFileSize) {
      props.changeImage(cropData)
    }
  }
  return (
    <>
      <div className={classes.imageCropper}>
        <Cropper
          style={{ width: '100%', overflow: 'hidden', margin: '20px 0' }}
          preview={`.${props.previewContainer}`}
          src={image}
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          scalable
          zoomable
          background
          responsive
          aspectRatio={props.imageRatio}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance)
          }}
        />
        <Button
          color='primary'
          onClick={getCropData}
        >{t('layout:useCroppedImage')}
        </Button>
      </div>
    </>
  )
}

export default ImageCropperComponent
