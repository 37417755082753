import de from './de.json'
import en from './en.json'

import crmDE from '@3m5/crude-frontend/dist/shared/translations/locales/de-DE.json'
import crmEN from '@3m5/crude-frontend/dist/shared/translations/locales/en-US.json'

const resources = {
  de: Object.assign(de, crmDE),
  en: Object.assign(en, crmEN),
}
export default resources
