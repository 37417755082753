import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Typography,
  Theme,
  Box,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from '../../../shared/components/DashboardComponent/CardComponent'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import BusinessIcon from '@mui/icons-material/Business'
import DomainAddIcon from '@mui/icons-material/DomainAdd'

import { User } from '../../../shared/models/Model'
import Env from '../../../shared/config/envConfig'
import { useTranslation } from 'react-i18next'

interface Props {
  theme: Theme
  user?: User
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  [theme.breakpoints.down('md')]: {
    '& td, th': {
      border: 0,
    },
    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },

}))

const DemoDashboard: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useTranslation('dashboard')

  const [mobileLayout, setMobileLayout] = useState(false)

  const [data, setData] = useState<any>()

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const restUrl = `${Env.restBase}/api/dashboard`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          setData(json)
        }
      })
      .catch(err => console.log(err))
  }, [],
  )

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  return (
    <Grid ref={rootRef} container className={classes.root}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: mobileLayout ? 'none' : theme.palette.primary.main, borderRadius: '10px', px: mobileLayout ? 0 : 4, py: mobileLayout ? 0 : 5 }}>
            {props.user && <Typography variant='h4' sx={{ color: mobileLayout ? theme.palette.text.primary : theme.palette.primary.contrastText }}>{t('greeting', { user: 'Kristin Dietze' })}</Typography>}
            <Typography variant='h6' sx={{ color: mobileLayout ? theme.palette.text.primary : theme.palette.primary.contrastText }}>{t('welcomeBack')}</Typography>
          </Box>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            const newPath = '/apps/crm/Company/'
            history.push(newPath)
          }}
        >
          <CardComponent sx={mobileLayout ? { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText } : undefined} height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <BusinessIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('companies')}</Typography>
              <Typography variant='h4'><b>{data?.companyCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            const newPath = '/apps/crm/Association/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <DomainAddIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('associations')}</Typography>
              <Typography variant='h4'><b>{data?.associationCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            const newPath = '/apps/crm/Person/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <GroupRoundedIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('persons')}</Typography>
              <Typography variant='h4'><b>{data?.personCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            const newPath = '/apps/crm/Guild/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <DomainAddIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('guilds')}</Typography>
              <Typography variant='h4'><b>{data?.guildCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid item xs={12} container spacing={3} direction={mobileLayout ? 'column-reverse' : 'row'}>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 280}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>{t('newestCompanies')}</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>{t('companyName')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('companyType')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('companyStreet')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('companyCity')}</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestCompanies.map((company: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>{t('companyName')}</Grid>
                                <Grid item xs={6}><Typography>{company.name}</Typography></Grid>
                                <Grid item xs={6}>{t('companyType')}</Grid>
                                <Grid item xs={6}><Typography>{company.type}</Typography></Grid>
                                <Grid item xs={6}>{t('companyStreet')}</Grid>
                                <Grid item xs={6}><Typography>{company.street}</Typography></Grid>
                                <Grid item xs={6}>{t('companyCity')}</Grid>
                                <Grid item xs={6}><Typography>{company.city}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell component='th' scope='row'>
                                  {company.name}
                                </TableCell>
                                <TableCell>{company.type}</TableCell>
                                <TableCell>{company.street}</TableCell>
                                <TableCell>{company.city}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 280}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>{t('newestPersons')}</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>{t('firstName')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('lastname')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('email')}</TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'phone' }}>{t('price')}</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestPersons.map((person: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>{t('firstName')}</Grid>
                                <Grid item xs={6}><Typography>{person.firstName}</Typography></Grid>
                                <Grid item xs={6}>{t('lastname')}</Grid>
                                <Grid item xs={6}><Typography>{person.lastName}</Typography></Grid>
                                <Grid item xs={6}>{t('email')}</Grid>
                                <Grid item xs={6}><Typography>{person.email}</Typography></Grid>
                                <Grid item xs={6}>{t('phone')}</Grid>
                                <Grid item xs={6}><Typography>{person.phone}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell component='th' scope='row'>
                                  {person.firstName}
                                </TableCell>
                                <TableCell>{person.lastName}</TableCell>
                                <TableCell>{person.email}</TableCell>
                                <TableCell>{person.phone}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default DemoDashboard
