import React from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Button, IconButton, Theme } from '@mui/material'
import { EntityData } from '../../../../crude-frontend/dist/shared/types/dataTypes'
import { makeStyles, createStyles } from '@mui/styles'
import Env from '../../shared/config/envConfig'

// temp interface - this will be provided by the crude project
interface InjectedTableActionComponentProps {
  entity: string
  entityId: string | number | boolean | null | undefined
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: '0 8px',
    },
    icon: {
      height: '30px',
      color: theme.palette.text.primary,
    },
  }),
)

const TableActionElement: React.FC<InjectedTableActionComponentProps> = (props) => {
  const classes = useStyles()

  const downloadExcelFile = () => {
    const url = `${Env.restBase}/api/export/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'Ordering.xlsx'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }
  if ((props.entity === 'Ordering') && (props.treeViewLevel <= 1)) {
    return (
      <Button variant='outlined' color='primary' className={classes.margin} onClick={() => downloadExcelFile()}>
        Export
      </Button>
    )
  } else {
    return (props.entity === 'Customer' && (props.treeViewLevel <= 1))
      ? (
        <a
          href={`mailTo:${props.entityData.mail}`}
        >
          <IconButton color='primary'>
            <MailOutlineIcon className={classes.icon} />
          </IconButton>
        </a>)
      : null
  }
}
export default TableActionElement
