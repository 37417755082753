import settingsActionCreatorFactory from 'typescript-fsa'
import { Theme } from '@mui/material'
import { Message } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
export const settingsActionCreator = settingsActionCreatorFactory('SETTINGS')

/**
 * Actions
 */

// settings actions
export const setPrefersDarkMode = settingsActionCreator<boolean>('SET_DARK_MODE')
export const setEditLayout = settingsActionCreator<any>('SET_EDIT_LAYOUT')
export const setAppLayout = settingsActionCreator<any>('SET_APP_LAYOUT')
export const setTheme = settingsActionCreator<Theme>('SET_THEME')
export const setCrudeAppName = settingsActionCreator<string>('SET_CRUDE_APP_NAME')
export const addMessage = settingsActionCreator<Message>('ADD_MESSAGE')
export const setUser = settingsActionCreator<any>('SET_USER')

export const setLogo = settingsActionCreator<any>('SET_Logo')
