import { createTheme, Theme, ThemeOptions } from '@mui/material'

const LightenDarkenColor = (col: string, amt: number) => {
  let usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  const num = parseInt(col, 16)

  let r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00FF) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000FF) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export const getAppTheme = (darkMode: boolean, hex: string) => {
  const lightTheme = {
    typography: {
      fontSize: 12,
    },
    overrides: {
      MuiAutocomplete: {
        option: {
          '&[data-focus="true"]': {
            color: hex,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            border: '2px solid',
            borderColor: hex,
          },
          '&$focused $notchedOutline': {
            borderColor: hex,
            borderWidth: 1,
          },
        },
      },
      MuiTabs: {
        root: {
          backgroundColor: '#f5f5f5',
          borderTop: '2px solid',
          borderColor: '#ebe7e9',
        },
      },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: '#ffffff',
            borderTop: '3px solid',
            borderColor: hex,
            color: hex,
          },
        },
      },
      MuiButton: {
        root: {
          border: `1px solid ${hex}`,
          color: hex,
          minWidth: '0',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#fafafa',
            border: `1px solid ${hex}`,
          },
        },
        outlinedSecondary: {
          border: `1px solid ${hex}`,
          color: hex,
          '&:hover': {
            backgroundColor: '#fafafa',
            border: `1px solid ${hex}`,
          },
        },
        outlinedPrimary: {
          color: '#ffffff',
          backgroundColor: hex,
          border: `1px solid ${hex}`,
          '&:hover': {
            backgroundColor: LightenDarkenColor(hex, 40),
            border: '1px solid #2985ba',
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            border: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          '&:hover': {
            color: hex,
          },
        },
      },
    },
    palette: {
      common: {
        white: '#ffffff',
        black: '#000000',
      },
      grey: {
        A100: '#f7f7f7',
        A200: '#ebe7e9',
        A700: '#0e1121',
      },
      background: {
        default: '#fafafa',
        paper: '#ffffff',
      },
      primary: {
        main: hex,
        light: LightenDarkenColor(hex, 40),
        dark: LightenDarkenColor(hex, -40),
      },
      secondary: {
        main: hex,
      },
    },
  }

  const darkTheme: ThemeOptions = {
    typography: {
      fontSize: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            border: `1px solid ${LightenDarkenColor(hex, 40)}`,
            minWidth: '0',
            boxShadow: 'none',
          },
          text: {
            color: LightenDarkenColor(hex, 40),
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: LightenDarkenColor(hex, 40),
        light: 'rgba(255, 255, 255, 0.5)',
        dark: '#121212',
      },
      grey: {
        A100: 'transparent',
        A200: 'rgba(255, 255, 255, 0.08)',
      },
      secondary: {
        main: LightenDarkenColor(hex, 40),
      },
      background: {
        default: '#121212',
        paper: '#39465c',
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.5)',
        secondary: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }

  const theme: Theme = createTheme(darkMode ? darkTheme : lightTheme)

  return theme
}
