import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, DialogTitle, TextField, DialogContent, DialogActions, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface Props {
  isOpenCallback: (e: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      width: '100%',
      margin: '10px',
    },
  }),
)

const ChangePassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const _onPasswordChange = () => {
    console.log('NEW PASSWORD')
    props.isOpenCallback(false)
  }

  const _onCancelEdit = () => {
    props.isOpenCallback(false)
  }

  return (
    <div>
      <DialogTitle>{t('settings:changePassword')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:oldPassword')}
              type='password'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:newPassword')}
              type='password'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              label={t('settings:confirmPassword')}
              type='password'
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancelEdit} color='secondary'>
          {t('common:cancel')}
        </Button>
        <Button onClick={_onPasswordChange} color='primary' autoFocus>
          {t('settings:changePassword')}
        </Button>
      </DialogActions>
    </div>
  )
}

export default ChangePassword
