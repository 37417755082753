import React from 'react'
import { Card, CardContent, SxProps } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'

const CardComponent: React.FC<{sx?: SxProps, padding?: number, className?: string, height?: ResponsiveStyleValue<string | number>, hoverEffect?: boolean }> = ({ sx, padding, className, height, hoverEffect, children }) => {
  return (
    <Card
      className={className} sx={{

        height,
        borderRadius: '10px',
        boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.16)',
        '&:hover': hoverEffect ? {
          transform: 'scale(1.03)',
          transitionDuration: '200ms',
        } : {},
        ...sx,
      }}
    >
      <CardContent sx={{ height: '100%', padding }}>
        {children}
      </CardContent>
    </Card>
  )
}
export default CardComponent
