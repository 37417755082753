// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { Message, Severity, EventType } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

const SnackbarComponent: React.FC = () => {
  // messages aus den Professocial Modulen anzeigen
  const defaultHideDuration = 3

  const [open, setOpen] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<Message | undefined>()
  const [hideDuration, setHideDuration] = React.useState<number | null>(defaultHideDuration)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false)
    setMessage(undefined)
  }

  const setNewMessage = (message: Message) => {
    setMessage(message)
    setOpen(true)
    setHideDuration(message && message.severity === Severity.error ? null : defaultHideDuration)
  }

  useEffect(() => {
    window.addEventListener('CrudeMessages', (({ detail }: CustomEvent) => {
      const message: Message = detail
      if (message && message.eventType !== EventType.load) {
        setTimeout(() => setNewMessage(message), 500)
      }
    }) as EventListener)
  },
  [],
  )

  return (
    <Snackbar open={open} autoHideDuration={hideDuration} onClose={handleClose}>
      <Alert variant='filled' onClose={handleClose} severity={message && message.severity} sx={{ width: '100%' }}>
        {message && message.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarComponent
