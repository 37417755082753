/**
 * CrudeFileUploadComponent
 *
 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Dialog, Button, DialogContent } from '@mui/material'
import ImageCropperComponent from './ImageCropperComponent'

interface Props {
  disabled?: boolean
  label?: string
  fullWidth?: boolean
  onUpload: (file: any) => void
}

const FileUploadComponent: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [file, setFile] = React.useState<File | undefined>()
  const [openImageCropper, setOpenImageCropper] = React.useState<boolean>(false)

  const acceptExtensions = ''

  useEffect(() => {
    const fileType = file && file.type
    const cropperTypes = ['ico', 'png', 'jpg', 'bmp', 'gif', 'jpeg', 'svg', 'webp']

    if (cropperTypes.findIndex((type: string) => `image/${type}` === fileType) !== -1) {
      setOpenImageCropper(true)
    } else {
      // file && props.onUpload(file)
    }
  }, [file])

  const onChange = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    setFile(files[0])
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result as any)
      props.onUpload && props.onUpload(reader.result as any)
    }
    reader.readAsDataURL(files[0])
  }

  const hiddenFileInput = React.useRef(null)
  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current.click()
  }

  const [image, setImage] = useState<string | undefined>()

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<UploadFileIcon />}
        color='primary'
        onClick={handleClick}
      >{t('layout:upload')}
      </Button>
      <input
        type='file'
        ref={hiddenFileInput}
        onInput={onChange}
        style={{ display: 'none' }}
        accept={acceptExtensions || '*'}
      />
      {image &&
        <Dialog
          open={openImageCropper}
          onClose={() => setOpenImageCropper(false)}
        >
          <DialogContent>
            <Box style={{ padding: '20px' }}>
              <div className='previewImage' />
              <ImageCropperComponent
                useOrigin={() => console.log('error')}
                image={image}
                previewContainer='previewImage'
                changeImage={(image: string) => {
                  if (file) {
                    const arr = image.split(',')
                    const bstr = atob(arr[1])
                    let n = bstr.length
                    const u8arr = new Uint8Array(n)

                    while (n--) {
                      u8arr[n] = bstr.charCodeAt(n)
                    }
                    setOpenImageCropper(false)
                  }
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>}
    </>
  )
}

export default FileUploadComponent
