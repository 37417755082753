import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { createStyles, makeStyles } from '@mui/styles'
import { Divider, FormControl, Grid, Paper, TextField, Typography, Button, Dialog, Theme, Box } from '@mui/material'

import ChangePassword from '../screens/ChangePassword'
import { User } from '../../../shared/models/Model'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    margin: {
      margin: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(2),
    },
    width: {
      width: '100%',
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
)

interface Props {
  user?: User
}

const Settings: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [name, setName] = React.useState(props.user ? props.user.name : '')
  const [email, setEmail] = React.useState(props.user ? props.user.email : '')
  const [phone, setPhone] = React.useState(props.user ? props.user.phone : '')
  const [showModal, setShowModal] = useState<boolean>(false)

  const renderChangePassword = (smallScreen: boolean) => (
    <Typography
      sx={{ display: smallScreen ? { xs: 'unset', sm: 'none' } : { xs: 'none', sm: 'unset' } }}
      variant='h6'
      className={`${classes.link} ${classes.margin}`}
      onClick={() => { setShowModal(!showModal) }}
    >{t('settings:changePassword')}
    </Typography>)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>
            {t('common:settings')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <div className={classes.container}>
              <Typography variant='h6' className={classes.margin}>
                {t('common:editProfile')}
              </Typography>
              {renderChangePassword(false)}
            </div>
            <Divider />
            <Grid container className={classes.padding}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.margin}
                    color='primary'
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setName(event.target.value) }}
                    label={t('settings:name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.margin}
                    color='primary'
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value) }}
                    label={t('settings:email')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.margin}
                    color='primary'
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPhone(event.target.value) }}
                    label={t('settings:phone')}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.padding}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', width: '100%', gap: '10px', flexWrap: 'wrap', flexDirection: 'row-reverse', alignItems: 'center' }}>
                  <Button
                    sx={{ height: 'fitContent' }}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      console.log('SAVE')
                    }}
                  >
                    {t('common:save')}
                  </Button>
                  <Button
                    sx={{ height: 'fitContent' }}
                    color='secondary'
                    variant='outlined'
                    onClick={() => {
                      history.push('/home')
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Box sx={{ flex: 1 }} />
                  {renderChangePassword(true)}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={showModal}
      >
        <ChangePassword isOpenCallback={(value: boolean) => setShowModal(value)} />
      </Dialog>
    </>
  )
}
export default Settings
